import React, { useState, useCallback } from "react";
import { navigate } from "gatsby-link";

import Layout from "../components/Layout";
import i18next from "../i18next";
import { useLanguageRedirect } from "../hooks";
import { SEO } from "../components/SEO";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const page = {
  title: "Contact | Tongue Tie App",
  description: "Get in touch",
  language: "en",
};

export default () => {
  const [state, setState] = useState({ isValidated: false });

  useLanguageRedirect("/contact");

  const handleChange = useCallback(({ target: { name, value } }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const form = e.target;
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...state,
        }),
      })
        .then(() => navigate(form.getAttribute("action")))
        .catch((error) => alert(error));
    },
    [state]
  );

  return (
    <Layout language="en">
      <SEO title={page.title} description={page.description} url={"/contact"} />
      <section className="section">
        <div className="container">
          <div className="content">
            <h1>{i18next.t("contactPage.title", { lng: "en" })}</h1>
            <form
              name="contact"
              method="post"
              action="/thanks"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact" />
              <div hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={handleChange} />
                </label>
              </div>
              <div className="field">
                <label className="label" htmlFor={"name"}>
                  {i18next.t("contactPage.name", { lng: "en" })}
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"text"}
                    name={"name"}
                    onChange={handleChange}
                    id={"name"}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={"email"}>
                  {i18next.t("contactPage.email", { lng: "en" })}
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={"email"}
                    name={"email"}
                    onChange={handleChange}
                    id={"email"}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={"message"}>
                  {i18next.t("contactPage.message", { lng: "en" })}
                </label>
                <div className="control">
                  <textarea
                    className="textarea"
                    name={"message"}
                    onChange={handleChange}
                    id={"message"}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <button className="button is-link" type="submit">
                  {i18next.t("contactPage.send", { lng: "en" })}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
};
